import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  SearchOutlined,
  BarChartOutlined,
  OrderedListOutlined,
  HomeOutlined,
  FileDoneOutlined,
  GiftOutlined,
  ShoppingOutlined,
  CoffeeOutlined,
  ShopOutlined,
  InfoCircleOutlined,
  BorderlessTableOutlined,
  CalendarOutlined,
  TeamOutlined,
  ContactsOutlined,
  BookOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

const dashBoardNavTree = [
  {
    key: 'categories',
    title: 'categories',
    breadcrumb: false,
    icon: OrderedListOutlined,
    submenu: [
      {
        key: 'Events',
        title: 'Events',
        breadcrumb: false,
        icon: ShoppingOutlined,
        customBackgroundColor: 'rgb(4 209 130 / 20%)',
        submenu: [
          {
            key: 'Category Events',
            path: `${APP_PREFIX_PATH}/category/events`,
            title: 'Category Events',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Product Events',
            path: `${APP_PREFIX_PATH}/product/events`,
            title: 'Product Events',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Filter Price Events',
            path: `${APP_PREFIX_PATH}/filter-events`,
            title: 'Filter Price Events',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Geographical areas',
            path: `${APP_PREFIX_PATH}/geographical-areas`,
            title: 'Geographical areas',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Geographical locations',
            path: `${APP_PREFIX_PATH}/geographical-locations`,
            title: 'Geographical locations',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Top Five Events',
            path: `${APP_PREFIX_PATH}/product/top-five-events`,
            title: 'Top Five Events',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'Gifts',
        title: 'Gifts',
        breadcrumb: false,
        icon: GiftOutlined,
        customBackgroundColor: 'rgb(59 255 237 / 20%)',
        submenu: [
          {
            key: 'Category Gifts',
            path: `${APP_PREFIX_PATH}/category/gift`,
            title: 'Category Gifts',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Product Gifts',
            path: `${APP_PREFIX_PATH}/product/gifts`,
            title: 'Product Gifts',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Top Five Gifts',
            path: `${APP_PREFIX_PATH}/product/top-five-gifts`,
            title: 'Top Five Gifts',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Filter Price Gift',
            path: `${APP_PREFIX_PATH}/filter`,
            title: 'Filter Price Gift',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'Culinary',
        title: 'Culinary',
        breadcrumb: false,
        icon: CoffeeOutlined,
        customBackgroundColor: 'rgb(255 158 59 / 20%)',
        submenu: [
          {
            key: 'Category Culinary',
            path: `${APP_PREFIX_PATH}/category/culinary`,
            title: 'Category Culinary',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Product Culinary',
            path: `${APP_PREFIX_PATH}/product/culinary`,
            title: 'Product Culinary',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Top Five Culinaries',
            path: `${APP_PREFIX_PATH}/product/top-five-culinaries`,
            title: 'Top Five Culinaries',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Filter Price Culinaries',
            path: `${APP_PREFIX_PATH}/filter-culinaries`,
            title: 'Filter Price Culinaries',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Geographical areas',
            path: `${APP_PREFIX_PATH}/geographical-areas`,
            title: 'Geographical areas',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Geographical locations',
            path: `${APP_PREFIX_PATH}/geographical-locations`,
            title: 'Geographical locations',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'Search',
    path: `${APP_PREFIX_PATH}/product/search`,
    title: 'Search',
    icon: SearchOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'Client side',
    title: 'Client side',
    breadcrumb: false,
    submenu: [
      {
        key: 'constructor',
        path: `${APP_PREFIX_PATH}/constructor`,
        title: 'Static Pages',
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'marketingConstructor',
        path: `${APP_PREFIX_PATH}/marketingConstructor`,
        title: 'Marketing static page',
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'homeEditorUnAuth',
        path: `${APP_PREFIX_PATH}/home-editor`,
        title: 'Homepage',
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'blogEditorAuth',
        path: `${APP_PREFIX_PATH}/blog-editor`,
        title: 'BlogPage',
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'contactEditorAuth',
        path: `${APP_PREFIX_PATH}/contact-editor`,
        title: 'ContactPage',
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'personalHome',
        title: 'Personal home',
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'months',
            path: `${APP_PREFIX_PATH}/months`,
            title: 'Months',
            icon: CalendarOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'homepagepositions',
            path: `${APP_PREFIX_PATH}/homepagepositions`,
            title: 'Home Page Positions',
            icon: HomeOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'about',
        path: `${APP_PREFIX_PATH}/about`,
        title: 'About',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'faq',
        path: `${APP_PREFIX_PATH}/faq`,
        title: 'FAQ',
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'footer',
        path: `${APP_PREFIX_PATH}/client-footer`,
        title: 'Footer',
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'footerData',
            path: `${APP_PREFIX_PATH}/client-footer`,
            title: 'Footer Data',
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'footerIcons',
            path: `${APP_PREFIX_PATH}/client-footer-icons`,
            title: 'Footer Social Networks',
            icon: InfoCircleOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'adminSide',
    title: 'Admin side',
    breadcrumb: false,
    icon: OrderedListOutlined,
    submenu: [
      {
        key: 'Users',
        path: `${APP_PREFIX_PATH}/users`,
        title: 'Users',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'Order',
        path: `${APP_PREFIX_PATH}/order-inquiries`,
        title: 'Order',
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'Suppliers',
        path: `${APP_PREFIX_PATH}/suppliers`,
        title: 'Suppliers',
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'contactUs',
        path: `${APP_PREFIX_PATH}/contacts-us`,
        title: 'Contact Us',
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'contactUsForSuppliers',
        path: `${APP_PREFIX_PATH}/contact-us-for-suppliers`,
        title: 'Contact Us For Suppliers',
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pageViews',
        path: `${APP_PREFIX_PATH}/page-views`,
        title: 'Page views',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'Calendar',
    title: 'Calendar',
    breadcrumb: false,
    submenu: [
      {
        key: 'Holidays',
        path: `${APP_PREFIX_PATH}/holidays`,
        title: 'Holidays',
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'Others',
    title: 'Others',
    breadcrumb: false,
    submenu: [
      {
        key: 'Tags',
        path: `${APP_PREFIX_PATH}/tags`,
        title: 'Tags',
        icon: BorderlessTableOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'Notifications',
        path: `${APP_PREFIX_PATH}/notifications`,
        title: 'Notifications',
        icon: ExclamationCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pluralisations',
        path: `${APP_PREFIX_PATH}/pluralisation`,
        title: 'Unit of measurement',
        icon: ContactsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'Global Events',
        path: `${APP_PREFIX_PATH}/global-events`,
        title: 'Global Events',
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

export const routeManager = [
  {
    key: 'Global Events',
    path: `${APP_PREFIX_PATH}/global-events`,
    title: 'Global Events',
    icon: CalendarOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'categories',
    title: 'categories',
    breadcrumb: false,
    icon: OrderedListOutlined,
    submenu: [
      {
        key: 'Events',
        title: 'Events',
        breadcrumb: false,
        icon: ShoppingOutlined,
        customBackgroundColor: 'rgb(4 209 130 / 20%)',
        submenu: [
          {
            key: 'Product Events',
            path: `${APP_PREFIX_PATH}/product/events`,
            title: 'Product Events',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Top Five Events',
            path: `${APP_PREFIX_PATH}/product/top-five-events`,
            title: 'Top Five Events',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'Gifts',
        title: 'Gifts',
        breadcrumb: false,
        icon: GiftOutlined,
        customBackgroundColor: 'rgb(59 255 237 / 20%)',
        submenu: [
          {
            key: 'Product Gifts',
            path: `${APP_PREFIX_PATH}/product/gifts`,
            title: 'Product Gifts',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Top Five Gifts',
            path: `${APP_PREFIX_PATH}/product/top-five-gifts`,
            title: 'Top Five Gifts',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'Culinary',
        title: 'Culinary',
        breadcrumb: false,
        icon: CoffeeOutlined,
        customBackgroundColor: 'rgb(255 158 59 / 20%)',
        submenu: [
          {
            key: 'Product Culinary',
            path: `${APP_PREFIX_PATH}/product/culinary`,
            title: 'Product Culinary',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Top Five Culinaries',
            path: `${APP_PREFIX_PATH}/product/top-five-culinaries`,
            title: 'Top Five Culinaries',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
]

const navigationConfig = [...dashBoardNavTree]

export default navigationConfig
