import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.getToken = function () {
	return fetch({
		url: '/csrf-cookie',
		method: 'get',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
	})
}

JwtAuthService.getAdminCheck = function () {
	return fetch({
		url: '/admin/check',
		method: 'get',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
	})
}

JwtAuthService.login = function (data) {
	return fetch({
		url: '/login',
		method: 'post',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
		data: data
	})
}

JwtAuthService.showUser = function (id) {
	return fetch({
		url: `/admin/users/${id}`,
		method: 'get',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
	})
}

JwtAuthService.setUserStatus = function (data) {
	return fetch({
		url: `/admin/user-statuses`,
		method: 'post',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
		data: data
	})
}

JwtAuthService.getHomePagePositions = function () {
	return fetch({
		url: `/home-page-positions`,
		method: 'get',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
	})
}

JwtAuthService.setHomePagePositions = function (data) {
	return fetch({
		url: `/admin/home-page-positions`,
		method: 'patch',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
		data: data
	})
}

JwtAuthService.logOut = function () {
	return fetch({
		url: `/logout`,
		method: 'post',
		withCredentials: true,
		headers: {
			'public-request': 'true',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			CrossOrigin: true
		},
	})
}

JwtAuthService.signUp = function (data) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
			'public-request': 'true'
		},
		data: data
	})
}

export default JwtAuthService