import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/homepagepositions`} component={lazy(() => import(`./homePagePositions`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./Users`))} />
        <Route path={`${APP_PREFIX_PATH}/users/:id`} component={lazy(() => import(`./Users`))} />
        <Route path={`${APP_PREFIX_PATH}/suppliers`} component={lazy(() => import(`./tableSuppliers`))} />
        <Route path={`${APP_PREFIX_PATH}/tags`} component={lazy(() => import(`./tags`))} />
        <Route path={`${APP_PREFIX_PATH}/global-events`} component={lazy(() => import(`./globalEvents`))} />
        <Route path={`${APP_PREFIX_PATH}/category/events`} component={lazy(() => import(`./category/events`))} />
        <Route path={`${APP_PREFIX_PATH}/category/gift`} component={lazy(() => import(`./category/gift`))} />
        <Route path={`${APP_PREFIX_PATH}/category/culinary`} component={lazy(() => import(`./category/culinary`))} />
        <Route path={`${APP_PREFIX_PATH}/product/events`} component={lazy(() => import(`./product/events`))} />
        <Route path={`${APP_PREFIX_PATH}/product/gifts`} component={lazy(() => import(`./product/gift`))} />
        <Route path={`${APP_PREFIX_PATH}/product/culinary`} component={lazy(() => import(`./product/culinary`))} />
        <Route path={`${APP_PREFIX_PATH}/product/top-five-events`} component={lazy(() => import(`./topFive/events`))} />
        <Route path={`${APP_PREFIX_PATH}/product/top-five-gifts`} component={lazy(() => import(`./topFive/gift`))} />
        <Route path={`${APP_PREFIX_PATH}/product/top-five-culinaries`} component={lazy(() => import(`./topFive/culinary`))} />
        <Route path={`${APP_PREFIX_PATH}/search`} component={lazy(() => import(`./search`))} />
        <Route path={`${APP_PREFIX_PATH}/client-footer`} component={lazy(() => import(`./footerData`))} />
        <Route path={`${APP_PREFIX_PATH}/client-footer-icons`} component={lazy(() => import(`./footerIcons`))} />
        <Route path={`${APP_PREFIX_PATH}/order-inquiries`} component={lazy(() => import(`./order`))} />
        <Route path={`${APP_PREFIX_PATH}/order-inquiries/:id`} component={lazy(() => import(`./order`))} />
        <Route path={`${APP_PREFIX_PATH}/months`} component={lazy(() => import(`./monthsEdit`))} />
        <Route path={`${APP_PREFIX_PATH}/pluralisation`} component={lazy(() => import(`./Pluralisation`))} />
        <Route path={`${APP_PREFIX_PATH}/home-editor`} component={lazy(() => import(`./homeEditor`))} />
        <Route path={`${APP_PREFIX_PATH}/blog-editor`} component={lazy(() => import(`./blogEditor`))} />
        <Route path={`${APP_PREFIX_PATH}/contact-editor`} component={lazy(() => import(`./contactEditor`))} />
        <Route path={`${APP_PREFIX_PATH}/notifications`} component={lazy(() => import(`./Notifications`))} />
        <Route path={`${APP_PREFIX_PATH}/contacts-us`} component={lazy(() => import(`./ContactUs`))} />
        <Route path={`${APP_PREFIX_PATH}/contact-us-for-suppliers`} component={lazy(() => import(`./ContactUsForSuppliers`))} />
        <Route path={`${APP_PREFIX_PATH}/contact-us-for-suppliers/:id`} component={lazy(() => import(`./ContactUsForSuppliers`))} />
        <Route path={`${APP_PREFIX_PATH}/about`} component={lazy(() => import(`./About`))} />
        <Route path={`${APP_PREFIX_PATH}/faq`} component={lazy(() => import(`./FAQ`))} />
        <Route path={`${APP_PREFIX_PATH}/page-views`} component={lazy(() => import(`./pageViews`))} />
        <Route path={`${APP_PREFIX_PATH}/geographical-locations`} component={lazy(() => import(`./Geographical`))} />
        <Route path={`${APP_PREFIX_PATH}/geographical-areas`} component={lazy(() => import(`./Geographical`))} />
        <Route path={`${APP_PREFIX_PATH}/filter`} component={lazy(() => import(`./Filter`))} />
        <Route path={`${APP_PREFIX_PATH}/filter-culinaries`} component={lazy(() => import(`./FilterCulinaries`))} />
        <Route path={`${APP_PREFIX_PATH}/filter-events`} component={lazy(() => import(`./FilterEvents`))} />
        <Route path={`${APP_PREFIX_PATH}/holidays`} component={lazy(() => import(`./holidays`))} />
        <Route path={`${APP_PREFIX_PATH}/constructor`} component={lazy(() => import(`./pageConstructor`))} />
        <Route path={`${APP_PREFIX_PATH}/marketingConstructor`} component={lazy(() => import(`./marketingConstructor`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/search`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);