import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, withRouter, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import JwtAuthService from "services/JwtAuthService";
import { signIn } from "../redux/actions/Auth"

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  const history = useHistory()
	const dispatch = useDispatch()
  const { pathname } = useLocation()
  useBodyClass(`dir-${direction}`);

  const [patch, setPatch] = useState(null)

  useEffect(() => {
    JwtAuthService.getAdminCheck()
      .then(res => {
        dispatch(signIn(res))
        setPatch(true)
        if (pathname.split('/')[1] !== 'admin') {
          history.push(`${APP_PREFIX_PATH}/home`)
        }
      })
      .catch(() => {
        setPatch(false)
        history.push(`${AUTH_PREFIX_PATH}/login`)
      })
  }, []) // eslint-disable-line

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        {patch === null ? (
          <div></div>
        ) : (
          <Switch>
            <Route exact path="/">
              <Redirect to={patch ? APP_PREFIX_PATH : AUTH_PREFIX_PATH} />
            </Route>

            
              <Route path={APP_PREFIX_PATH}>
                <AppLayout direction={direction} location={location} />
              </Route>

              <Route path={AUTH_PREFIX_PATH}>
                <AuthLayout direction={direction} />
              </Route>


          </Switch>
        )}
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));