import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';
import JwtAuthService from 'services/JwtAuthService';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
// const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	}

	if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		// history.push(ENTRY_ROUTE)
		// window.location.reload();
	}

	return config
}, error => {
	console.log(error?.response?.message)
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {

	return response.data
}, (error) => {
	if (error?.response?.data?.message === "CSRF token mismatch.") {
		JwtAuthService.getToken()
	}

	let notificationParam = {
		message: "ERROR",
		className: 'notification-new-line',
		duration: 10
	}

	if (error?.response?.data?.message?.length) {
		notificationParam.message = error?.response?.data?.message
	}
	if (error?.response?.data?.errors) {
		notificationParam.message = notificationParam.message + '\n'
		const obj = error?.response?.data?.errors
		const stringError = Object?.values(obj)?.join('\n \n')
		notificationParam.message = stringError
	}
	if (error?.response?.data === 'Already logged in'){
		window.location.href = '/'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service